// This is the JS code for the rivets.js Cart manipulation

// Load Cart data
var cart = getCart();
var items = cart.order_items;
var order = cart.order;
items.forEach(getName);

function getCart() {
  $.ajax({
    url : "/cart",
    async: false,
    dataType: 'json',
    success: function (result) {
      cart = result;
    }
  });

  return cart
};

// Data to bind
var data = {
    items,
    order
  },
  controller = {
    addQty: function(e, model) {
      model.cart.items[model.index].quantity++;
      updateItem(model.cart.items[model.index]);
      model.controller.updateOrder(model.cart);
    },

    addItem: function(e, model) {
      setCartActive();
      i = 0;

      size = $('.product_size_wrapper').find('input:checked').length ? $('.product_size_wrapper').find('input:checked').attr('data-size') : $(this).data('product-size');

      for (; i< model.cart.items.length; i++) {
        if( model.cart.items[i].product_id == $(this).data('product-id') && model.cart.items[i].size == size) {
          model.cart.items[i].quantity++;
          updateItem(model.cart.items[i]);
          model.controller.updateOrder(model.cart);
          return;
        }
      }
      createItem($(this).data('product-id'), size);
      model.controller.updateCartData(e, model);
      model.controller.updateOrder(model.cart);
    },

    removeQty: function(e, model) {
      var index = model.index,
        item = model.cart.items[index]

      if(item.quantity > 1) {
        item.quantity--;
        updateItem(model.cart.items[model.index]);
        model.controller.updateOrder(model.cart);
        return;
      }
      deleteItem(model.cart.items[model.index]);
      model.cart.items.splice(index, 1);
      model.controller.updateOrder(model.cart);
    },

    updateOrder: function(data) {
      var bag = data.items,
        order = data.order,
        order_item_quantity = 0,
        order_subtotal = 0,
        order_total = 0,
        i = 0;

      for(; i < bag.length; i++) {
        item = bag[i];
        subtotal = item.unit_price * item.quantity;
        order_item_quantity += item.quantity;
        item.total_price = subtotal;
        order_subtotal += subtotal;
        order_total += subtotal * (1 + (order.tax != null ? (order.tax) : (0)));
      }
      order.item_quantity = order_item_quantity;
      order.subtotal = order_subtotal;
      order.total = order_total;
      $('.reload-express-payment-button').trigger('click');
    },
    updateCartData: function(e, model) {
      newcartdata = getCart();
      newitems = newcartdata.order_items;
      newitems.forEach(getName);
      model.cart.items = newitems;
    }
  };

function createItem(product_id, size) {
  $.ajax({
   url: '/order_items',
   async: false,
   type:'POST',
   dataType:'json',
   data:{
       order_item: {
         product_id: product_id,
         size: size,
         quantity: "1"
       },
       authenticity_token: window._token
   },
   success:function(data){
   },
   error:function(data){
       alert("something went wrong" + JSON.stringify(data));
   }
  });
};

function updateItem(item) {
  url = '/order_items/' + item.id;
  $.ajax({
   url: url,
   async: false,
   type:'POST',
   dataType:'json',
   data:{
    _method: 'patch',
    order_item: {
       product_id: item.product_id,
       size: item.size,
       quantity: item.quantity
    },
    authenticity_token: window._token
   },
   success:function(data,textStatus,jqXHR){
     $('.cart-item-error').remove();
     if (jqXHR.status == "204") {
       $('.item-image').find("[href*='" + item.product_name.replace(/\s+/g, '-').toLowerCase() + "']").closest('.cart-row').after("<div class='cart-item-error'>Oups 🥺! les derniers modèles sont partis</div>");
       item.quantity--;
    }
   },
   error:function(data){
       alert("something went wrong" + JSON.stringify(data));
   }
  });
};

function deleteItem(item) {
  url = '/order_items/' + item.id;
  $.ajax({
   url: url,
   async: false,
   type:'DELETE',
   dataType:'json',
   data:{
    authenticity_token: window._token
   },
   success:function(data){
   },
   error:function(data){
       alert("something went wrong" + JSON.stringify(data));
   }
  });
};

function getName(item, index, array) {
  url = "/products/json/" + item.product_id;
  $.ajax({
    url : url,
    async: false,
    dataType: 'json',
    success: function (result) {
      item.product_name = result.name
      item.image = result.img_url
      item.url = result.product_url
    }
  });
};

function setCartActive() {
  $(".site-header").css("position","fixed");
  $(".site-header").css("top","0");
  $($('.cart-container').attr('class').split(' ')).each(function() {
    if (this == 'active') {
        return;
    }
  });
  $('.cart-container').toggleClass('active');
  $('.checkout-container').toggleClass('active');
  $('.site-cache').addClass('active');
  $('.cart-container').animate({ scrollTop: $('.cart-container')[0].scrollTop + 100}, 500);
};

// Declaration of rivets.js formatters, useful for binded data manipulation

rivets.formatters.length = function(val) {
  return val.length;
};

rivets.formatters.lt = function(a, b) {
        return b > a;
};
rivets.formatters.gt = function(a, b) {
        return a > b;
};

rivets.formatters.append = function(value, appendee) {
    return value + appendee;
};

rivets.formatters.currency = function(val) {
    return (Math.round((parseFloat(val) * 100)) / 100).toFixed(2) + "€"
};

// Data binding
rivets.bind($('body'), {
  cart: data,
  controller: controller
});
