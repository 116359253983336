window.lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    callback_loaded: (img) => {
      $('.automatic_color').each(function() {
        breakOut = false;
        var relative_parent = $(this).parents().filter(function() {
          // reduce to only relative position or "body" elements
          var $this = $(this);
          return $this.is('body') || $this.css('position') == 'relative';
        }).slice(0,1);
        var automatic_element = this;
        relative_parent.find('img').each(function() {
          if ($(this).is($(img))) {
            t = parseFloat($(automatic_element).attr('data-invert-threshold')) || 0.5;
            if (getAverageRGB(automatic_element, img) > t) {
              relative_parent.find('.automatic_color').css('filter', 'invert(1)');
              relative_parent.find('.automatic_color_no_check').css('filter', 'invert(1)');
              breakOut = true;
              return false;
            }
          }
        });
        if (breakOut) {
          return false;
        }
      });
    }
});

window.getAverageRGB = function(element,img) {
  var blockSize = 5, // only visit every 5 pixels
    defaultRGB = {r:0,g:0,b:0}, // for non-supporting envs
    canvas = document.createElement('canvas'),
    context = canvas.getContext && canvas.getContext('2d'),
    data, width, height,
    i = -4,
    length,
    rgb = {r:0,g:0,b:0},
    count = 0;

  if (!context) {
    return defaultRGB;
  }

  var sx = 0
  var sy = 0

  height = canvas.height = element.naturalHeight || element.offsetHeight || element.height;
  width = canvas.width = element.naturalWidth || element.offsetWidth || element.width;

  var img_height = img.naturalHeight || img.offsetHeight || img.height;
  var img_width = img.naturalWidth || img.offsetWidth || img.width;
  var img_y_ratio = img.height / img.naturalHeight;
  var img_x_ratio = img.width / img.naturalWidth;
  sx = ($(element).offset().left - $(img).offset().left) / img_x_ratio;
  sy = ($(element).offset().top - $(img).offset().top) / img_y_ratio;
  if (sx < 0) {
    sx = 0;
  }
  if (sy < 0) {
    sy = 0;
  }
  width = Math.min(img_width, $(element).width());
  height = Math.min(img_height, $(element).height());

  context.drawImage(img, sx, sy, width, height, 0, 0, width, height);

  try {
    data = context.getImageData(0, 0, width, height);
  } catch(e) {
    /* security error, img on diff domain */
    return defaultRGB;
  }

  length = data.data.length;

  while ( (i += blockSize * 4) < length ) {
    ++count;
    rgb.r += data.data[i];
    rgb.g += data.data[i+1];
    rgb.b += data.data[i+2];
  }

  // ~~ used to floor values
  rgb.r = ~~(rgb.r/count) * 299;
  rgb.g = ~~(rgb.g/count) * 597;
  rgb.b = ~~(rgb.b/count) * 114;

  return (rgb.r + rgb.g + rgb.b) / 255000;
}
