(async () => {
  'use strict';

  var config = {
    country : "FR",
    stripeCountry : "FR",
    currency : "eur"
  };

  const order = await getOrderStatus();

  var order_info = {
    subtotal: parseFloat(order.order.subtotal).toFixed(2),
    total: parseFloat(order.order.total).toFixed(2),
  }

  const form = document.getElementById('checkout-form');
  const submitButton = form.querySelector('button[type=submit]');
  submitButton.disabled = true;
  // Create a Stripe client.
  const stripe = Stripe(
    $('.paybutton').data("pk-stripe"),
    {betas: ['payment_intent_beta_3']},
  );

  const elements = stripe.elements({
    fonts: [
        {
          family: 'Helvetica Neue Condensed',
          src: 'url(assets/HelveticaNeueLTPro-Cn.woff2)',
          style: 'normal',
        }
     ]
  });

  // Prepare the styles for Elements.
  const style = {
    base: {
      iconColor: '#666ee8',
      color: '#050202',
      fontSize: '16px',
      fontFamily:
        'Futura-CondensedExtraBold',
      fontSmoothing: 'subpixel-antialiased',
      '::placeholder': {
        color: 'darkgray',
        textTransform: 'uppercase',
      },
      ':focus::placeholder': {
        color: 'rgba(5, 2, 2, 0.4)'
      },
      ':-webkit-autofill': {
        color: '#666ee8',
      },
    },
  };

  const classes = {
    base: 'form-control',
  };


  /**
   * Implement a Stripe Card Element that matches the look-and-feel of the app.
   *
   * This makes it easy to collect debit and credit card payments information.
   */

  // Create a Card Element and pass some custom styles to it.
  const card = elements.create('card', {hidePostalCode: true,style,classes});

  // Mount the Card Element on the page.
  card.mount('#card-element');

  // Monitor change events on the Card Element to display any errors.
  card.on('change', ({empty, complete, error}) => {
    submitButton.disabled = true;
    const cardErrors = document.getElementById('card-errors');
    document.getElementById('card-element').classList.remove('is-valid');
    document.getElementById('card-element').classList.remove('is-invalid');
    if (error) {
      cardErrors.textContent = error.message;
      document.getElementById('card-element').classList.remove('is-valid');
      document.getElementById('card-element').classList.add('is-invalid');
      cardErrors.classList.add('visible');
    } else {
      cardErrors.classList.remove('visible');
    }
    if (complete) {
      document.getElementById('card-element').classList.add('is-valid');
      if ($('input.is-valid:visible').length >= $(':input[required]:visible').length) {
        submitButton.disabled = false;
      }
    }
  });

  // Create the payment request.
  const paymentRequest = stripe.paymentRequest({
    country: config.stripeCountry,
    currency: config.currency,
    total: {
      label: 'Total',
      amount: parseInt((order_info.total * 100).toFixed()),
    },
    requestShipping: true,
    requestPayerEmail: true,
    requestPayerPhone: true,
    shippingOptions: [
      {
        id: 'free',
        label: 'Frais de port offerts à partir de 30€',
        detail: 'Délai de livraison en fonction du produit. Voir rubrique Livraison.',
        amount: 0,
      },
    ],
  });

  loadPaypalButtons()

  // Callback when a source is created.
  paymentRequest.on('paymentmethod', async event => {
  try {
      const allowed_source_types = [];
      allowed_source_types.push("card");
      var auth_token = $('meta[name="csrf-token"]').attr('content');
      order.paymentIntent = await createIntents(allowed_source_types, auth_token);
      const {error} = await stripe.confirmCardPayment(
        order.paymentIntent.client_secret,
        {
          payment_method: event.paymentMethod.id,
          shipping: {
            name: event.shippingAddress.recipient,
            phone: event.shippingAddress.phone,
            address: {
              line1: event.shippingAddress.addressLine[0],
              city: event.shippingAddress.city,
              postal_code: event.shippingAddress.postalCode,
              state: event.shippingAddress.region,
              country: event.shippingAddress.country,
            },
          },
        }
      );

      if (error) {
        event.complete('fail');
        await handleOrder({metadata: {status: 'failed'}}, null, error);
      } else {
        event.complete('success');
        appendToForm({
          'input[id=firstName]': event.shippingAddress.recipient.split(" ")[0],
          'input[id=lastName]': event.shippingAddress.recipient.split(" ")[1],
          'input[name=mail]': event.payerEmail,
          'input[id=phone]': event.payerPhone,
          'input[name=address]': event.shippingAddress.addressLine[0],
          'input[name=city]': event.shippingAddress.city,
          'input[name=zipcode]': event.shippingAddress.postalCode,
          'select[name=country] option:checked': event.shippingAddress.country,
        });
        $('.credit-card-form').removeClass('hide');
        mainElement.classList.add('success');
        await handleOrder({metadata: {status: 'paid'}}, {type: "stripe", id: order.paymentIntent.id}, null);
      }
    } catch (error) {
      event.complete('fail');
    }
  });

  $('#country').change(function(){
    var fd = new FormData();
    fd.append("shipping_type", $('#country').val());
    $.ajax("/orders/shipping_fee_update/" + order.order.id, {
      method: "POST",
      data: fd,
      async: false,
      dataType:'json',
      processData: false,
      contentType: false,
      success: function (data) {
      },
      error: function () {
        console.log('Shipping fee update error');
      }
    });

    $("html, body").animate({ scrollTop: 0 }, "slow");

    getOrderStatus().then((order) => {
      order_info.total        = parseFloat(order.order.total).toFixed(2);
      order_info.shipping_fee = 0;
      order_info.subtotal = parseFloat(order.order.subtotal).toFixed(2);

      $(".shipping_fee_amount").html(order_info.shipping_fee + '€');
      $(".shipping_note").html('Frais de livraison en fonction du pays sélectionné');
      $(".shipping-list-item").css("border-color", "#40a798");
      $(".shipping-list-item").css("box-shadow", "0 0 0 1px #40a798");
      $(".shipping-list-item").css("margin-bottom", "1px");
      if ($(".total_after_discount_with_discount").length) {
        $(".total_order_amount").html((parseFloat(order_info.subtotal) + parseFloat(order_info.shipping_fee)).toFixed(2) + '€');
        $(".total_after_discount_with_discount").html(' ' + order_info.total + '€');
        $(".paybutton").html('PAYER ' + order_info.total + '€');
      } else {
        $(".total_order_amount").html(order_info.total + '€');
        $(".paybutton").html('PAYER ' + order_info.total + '€');
      };
    });


  });

  // Callback when the shipping address is updated.
  paymentRequest.on('shippingaddresschange', event => {
    var fd = new FormData();
    var country;
    switch (event.shippingAddress.country) {
      case 'FR':
        country = 'fr';
        break;
      case 'US':
        country = "usa";
        break;
      default:
        country = "ue";
    }

    if ($('.pick-up-button').hasClass('active')) {
      event.updateWith({
        status: 'success',
        shippingOptions: [
          {
            id: 'free',
            label: 'Pas de frais de port',
            detail: 'Remise en main propre',
            amount: 0,
          },
        ],
      })
      return;
    }

    getOrderStatus().then((order) => {
      order_info.total        = parseFloat(order.order.total).toFixed(2);
      order_info.shipping_fee = 0
      order_info.subtotal = parseFloat(order.order.subtotal).toFixed(2);
      event.updateWith({
        status: 'success',
        total: {
          label: 'Total',
          amount: parseInt(order_info.total * 100),
        },
        shippingOptions: [
          {
            id: 'special',
            label: 'frais de port offerts à partir de 30€',
            detail: 'Ca dépend du produit',
            amount: parseInt(order_info.shipping_fee * 100),
          },
        ],
      });
    });
  });

  // Create the Payment Request Button.
  const paymentRequestButton = elements.create('paymentRequestButton', {
    paymentRequest: paymentRequest,
    style: {
      paymentRequestButton: {
        height: "45px",
      },
    },
  });

  // Check if the Payment Request is available (or Apple Pay on the Web).
  const paymentRequestSupport = await paymentRequest.canMakePayment();
  if (paymentRequestSupport) {
    // Display the Pay button by mounting the Element in the DOM.
    paymentRequestButton.mount('#payment-request-button');
    document.getElementById('payment-request').classList.add('visible');
    document.getElementById('payment-request').style.padding = "0 0 20px 0"
    // Replace the instruction.
  }

  /**
   * Handle the form submission.
   *
   * This creates an order and either sends the card information from the Element
   * alongside it, or creates a Source and start a redirect to complete the purchase.
   *
   * Please note this form is not submitted when the user chooses the "Pay" button
   * or Apple Pay, Google Pay, and Microsoft Pay since they provide name and
   * shipping information directly.
   */


  form.addEventListener('submit', async event => {
    event.preventDefault();

    // Retrieve the user information from the form.
    const payment = $('.checkout-button').hasClass('active')? 'card' : 'express';
    const name = form.querySelector('input[name=cc-name]').value;
    const country = form.querySelector('select[name=country] option:checked')
      .value;
    const email = form.querySelector('input[name=mail]').value;
    const shipping = {
      name,
      address: {
        line1: form.querySelector('input[name=address]').value,
        city: form.querySelector('input[name=city]').value,
        postal_code: form.querySelector('input[name=zipcode]').value,
        country,
      },
    };
    submitButton.disabled = true;
    submitButton.textContent = 'Paiement en cours…';

    const usesPaymentIntent = payment === 'card';
    const allowed_source_types = [];
    allowed_source_types.push("card");
    var auth_token = $('meta[name="csrf-token"]' ).attr('content');
    order.paymentIntent = await createIntents(allowed_source_types, auth_token);
    if (usesPaymentIntent) {
      // Let Stripe handle source activation
      const {paymentIntent, error} = await stripe
      .handleCardPayment(order.paymentIntent.client_secret, card, {
        source_data: {
          owner: {
            name,
          },
        },
      });
      if (error) {
        await handleOrder({metadata: {status: 'failed'}}, null, error);
      } else if (paymentIntent.status === 'succeeded') {
        await handleOrder({metadata: {status: 'paid'}}, {type: "stripe", id: paymentIntent.id}, null);
      }
    } else {
      // Prepare all the Stripe source common data.
      const sourceData = {
        type: payment,
        amount: order.amount,
        currency: order.currency,
        owner: {
          name,
          email,
        },
        redirect: {
          return_url: window.location.href,
        },
        statement_descriptor: 'Stripe Payments Demo',
        metadata: {
          order: order.id,
        },
      };

      // Create a Stripe source with the common data and extra information.
      const {source, error} = await stripe.createSource(sourceData);
      await handleOrder(order, source, error);
    }
  });

  // Handle the order and source activation if required
  const handleOrder = async (order, source, error = null) => {
    const mainElement = document.getElementById('payment');
    const confirmationElement = document.getElementById('confirmation');
    if (error) {
      mainElement.classList.remove('processing');
      mainElement.classList.remove('receiver');
      confirmationElement.querySelector('.error-message').innerText =
        error.message;
      mainElement.classList.add('error');
    }
    switch (order.metadata.status) {
      case 'pending':
        // Success! Now waiting for payment confirmation. Update the interface to display the confirmation screen.
        mainElement.classList.remove('processing');
        // Update the note about receipt and shipping (the payment is not yet confirmed by the bank).
        confirmationElement.querySelector('.note').innerText =
          'We’ll send your receipt and ship your items as soon as your payment is confirmed.';
        mainElement.classList.add('success');
        break;

      case 'failed':
        // Payment for the order has failed.
        mainElement.classList.remove('success');
        mainElement.classList.remove('processing');
        mainElement.classList.remove('receiver');
        mainElement.classList.add('error');
        $('header').css("display", "");
        $('header').css("top", "0");
        $('.status').css("position", "relative");
        break;

      case 'paid':
        // Success! Payment is confirmed. Update the interface to display the confirmation screen.
        mainElement.classList.remove('processing');
        mainElement.classList.remove('receiver');
        userInfoUpdate();
        validateShipping(source);
        // Update the note about receipt and shipping (the payment has been fully confirmed by the bank).
        $('header').css("display", "");
        $('header').css("top", "0");
        $('.status').css("position", "relative");
        $('.cart').addClass('hide');
        $('.checkout-button').addClass('hide');
        $('.shipping-info-form').children().css('display', 'none');
        $('.credit-card-form').children().css('display', 'none');
        $(confirmationElement).css('display','');
        $(confirmationElement).css('position','relative');
        confirmationElement.querySelector('.note').innerText =
          "ON VA T'ENVOYER UN SMS AVEC L'ENDROIT ET L'HEURE POUR VENIR RÉCUPERER TA PIZZA. MATES TES MAILS POUR SUIVRE L'AVANCEMENT DE LA COMMANDE 😉";
        mainElement.classList.add('success');
        window.setTimeout(function(){
          // Move to a new location or you can do something else
          window.location.href = "/";
        }, 8000);
        break;
    }
  };

  const mainElement = document.getElementById('payment');
  if ( window.location.search.includes('source')) {
    // Update the interface to display the processing screen.
    mainElement.classList.add('success', 'processing');
  } else {
    // Update the interface to display the checkout form.
    mainElement.classList.add('checkouty');
  }

  function appendToForm(data) {
    $.each(data, function(key, value){
      if(value){
        form.querySelector(key).value = value;
      };
    });
  };

  function validateShipping(source) {
    var fd = new FormData();
    fd.append("authenticity_token", $('meta[name="csrf-token"]').attr('content'));
    fd.append("shipping[mail]", form.querySelector('input[name=mail]').value);
    fd.append("shipping[surname]", form.querySelector('input[id=lastName]').value);
    fd.append("shipping[name]", form.querySelector('input[id=firstName]').value);
    fd.append("shipping[phone]", form.querySelector('input[id=phone]').value);
    fd.append("shipping[address]", form.querySelector('input[name=address]').value);
    fd.append("shipping[city]", form.querySelector('input[name=city]').value);
    fd.append("shipping[country]", form.querySelector('select[name=country] option:checked').value);
    fd.append("shipping[zipcode]", form.querySelector('input[name=zipcode]').value);
    fd.append("payment_type", source.type);
    fd.append("payment_id", source.id);
    $.ajax("/shipping", {
      method: "POST",
      data: fd,
      async: false,
      dataType:'json',
      processData: false,
      contentType: false,
      success: function (data) {
      },
      error: function () {
        console.log('Shipping creation error');
      }
    });
  }

  function appendUserinfo(fd) {
    fd.append("authenticity_token", $('meta[name="csrf-token"]').attr('content'));
    fd.append("userinfo[mail]", form.querySelector('input[name=mail]').value);
    fd.append("userinfo[surname]", form.querySelector('input[id=lastName]').value);
    fd.append("userinfo[name]", form.querySelector('input[id=firstName]').value);
    fd.append("userinfo[phone]", form.querySelector('input[id=phone]').value);
    fd.append("userinfo[address]", form.querySelector('input[name=address]').value);
    fd.append("userinfo[city]", form.querySelector('input[name=city]').value);
    fd.append("userinfo[country]", form.querySelector('select[name=country] option:checked').value);
    fd.append("userinfo[zipcode]", form.querySelector('input[name=zipcode]').value);
  };

  function userInfoUpdate(){
    if (form.querySelector('input[id=save-info]').dataset.info) {
      if (form.querySelector('input[id=save-info]').checked) {
        var uifd = new FormData();
        appendUserinfo(uifd);
        $.ajax("/userinfo/" + form.querySelector('input[id=save-info]').dataset.info, {
          method: "PATCH",
          async: false,
          data: uifd,
          dataType:'json',
          processData: false,
          contentType: false,
          success: function (data) {
          },
          error: function () {
            console.log('Userinfo update error');
          }
        });
      }
      else {
        $.ajax("/userinfo/" + form.querySelector('input[id=save-info]').dataset.info, {
          method: "DELETE",
          async: false,
          success: function (data) {
          },
          error: function () {
            console.log('Userinfo deletion error');
          }
        });
      };

    };
    if (form.querySelector('input[id=save-info]').checked) {
      var uifd = new FormData();
      appendUserinfo(uifd);
      $.ajax("/userinfo", {
        method: "POST",
        async: false,
        data: uifd,
        dataType:'json',
        processData: false,
        contentType: false,
        success: function (data) {
        },
        error: function () {
          console.log('Userinfo creation error');
        }
      });
    };
  };

  async function getOrderStatus() {
    try {
      const response = await fetch('/cart', {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }});
      return await response.json();
    } catch (err) {
      return {error: err};
    }
  };

  $('.reload-express-payment-button').on('click', function() {
    reloadExpressPayments();
  })

  function reloadExpressPayments(options = {}) {
    getOrderStatus().then((order) => {
      order_info.total        = parseFloat(order.order.total).toFixed(2);
      order_info.shipping_fee = 0
      order_info.subtotal = parseFloat(order.order.subtotal).toFixed(2);

      if (options.no_shipping) {
        var shipping_options = {
          shippingOptions: [
            {
              id: 'free',
              label: 'Pas de frais de port',
              detail: 'Remise en main propre',
              amount: 0,
            },
          ]
        };
      } else {
        var shipping_options = {
          shippingOptions: [
            {
              id: 'special',
              label: 'Frais de port offerts à partir de 30€',
              detail: 'Délai de livraison en fonction du produit. Voir rubrique Livraison.',
              amount: 0,
            },
          ]
        };
      };

      paymentRequest.update({
        total: {
          label: 'Total',
          amount: parseInt((order_info.total * 100).toFixed(2)),
        },
        ...shipping_options,
      });

      $("#paypal-button-container").empty();
      loadPaypalButtons();
    });
  };

  function loadPaypalButtons() {
    paypal.Buttons({
      style: {
        layout: 'horizontal',
        label: 'pay',
        tagline: false,
        color: 'black'
      },
      createOrder: function(data, actions) {
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: order_info.total,
              currency_code: 'EUR'
            }
          }]
        });
      },
      onApprove: function(data, actions) {
        return actions.order.capture().then(function(details) {
          form.querySelector('input[id=firstName]').value = details.payer.name.given_name;
          form.querySelector('input[id=lastName]').value = details.payer.name.surname;
          form.querySelector('input[name=mail]').value = details.payer.email_address;
          form.querySelector('input[name=address]').value = details.purchase_units[0].shipping.address.address_line_1;
          form.querySelector('input[name=zipcode]').value = details.purchase_units[0].shipping.address.postal_code;
          form.querySelector('input[name=city]').value = details.purchase_units[0].shipping.address.admin_area_2;
          form.querySelector('select[name=country] option:checked').value = details.purchase_units[0].shipping.address.country_code;
          form.querySelector('input[id=phone]').value = details.payer.phone.phone_number.national_number;
          $('.credit-card-form').removeClass('hide');
          mainElement.classList.add('success');
          handleOrder({metadata: {status: 'paid'}}, {type: "paypal", id: details.id}, null);
        });
      }
    }).render('#paypal-button-container');
  };

  async function createIntents(allowed_source_types, authenticity_token) {
    try {
      const response = await fetch('/checkout', {
        method: 'POST',
        headers: {'Accept': 'application/json',
        'Content-Type': 'application/json'},
        body: JSON.stringify({
          allowed_source_types,
          authenticity_token
        }),
      });
      const data = await response.json();
      if (data.error) {
        return {error: data.error};
      } else {
        // Save the current order locally to lookup its status later.
        return data;
      }
    } catch (err) {
      return {error: err.message};
    }
  };
})();
